<template>
	<div
		v-if="printLoading"
		class="modal justify-center flex text-center items-center">
		<tw-loading />
		<div
			v-if="printLoading"
			class="absolute top-0 right-0 w-screen h-screen flex justify-center items-center pt-16 font-bold font-gotham text-brand-500">
			<div>{{ t("job.creatingInstructions") }}</div>
		</div>
	</div>
	<div>
		<div class="flex justify-between items-center pb-3 mb-8 border-b-2 border-brand-400">
			<tw-breadcrumb 
				:crumbs="breadcrumb" />
		</div>
		<div class="flex justify-between items-center w-full gap-4">
			<div
				v-show="loading"
				class="h-6 rounded-full bg-gray-300 animate-pulse w-1/2  mb-8" />
			<h1
				v-show="!loading"
				class="page-title"
				:class="{'text-indigo-500' : job?.fieldData?.['job__JOBS__kfJobsID::_kp_jobs_id']}">
				{{ job?.fieldData?.name_bride_groom_display }}
			</h1>
			<div 
				v-if="userStore.getContactType == 'Staff'"
				@click="printInstructions()"
				class="flex justify-end gap-2 items-center linked-icon cursor-pointer">
				<div>
					<img
						class="h-6 w-6"
						src="@/assets/icons/solid/print-fill.svg"
						alt="printer icon">
				</div>
				<div>
					{{ t("job.printInstructions") }}
				</div>
			</div>			
		</div>
		<div class="grid grid-cols-2 gap-4 lg:gap-6">
			<!-- job details & locations -->
			<div :class="[userStore.getContactType === 'Staff' ? 'col-span-2' : 'col-span-2 lg:col-span-1']">
				<collapsable-card 
					:title="t('job.jobDetails')">
					<JobDetailsLocation 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- covid restrictions -->
			<div
				v-if="userStore.getContactType != 'Staff'"
				class="col-span-2 lg:col-span-1">
				<collapsable-card
					:title="_localesStore.getLabel('278')">
					<JobCovidRestrictions
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- transportation -->
			<div 
				v-if="userStore.getContactType === 'Staff'"
				class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('185')">
					<JobTransportationDetails
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- meeeting places -->
			<div 
				v-if="userStore.getContactType === 'Staff'"
				class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('427')">
					<JobMeetingPlaces
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- locations -->
			<div 
				v-if="userStore.getContactType === 'Staff'"
				class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('218')">
					<JobLocations
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- items -->
			<div
				v-if="userStore.getContactType === 'Staff'" 
				class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('187')">
					<JobItems 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>
				
			<!-- additional details -->
			<div class="col-span-2">
				<collapsable-card 
					:title="t('job.additionalDetails')">
					<JobAdditionalDetails 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- discounts -->
			<div
				v-if="userStore.getContactType != 'Staff'"
				class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('153')">
					<JobDiscounts 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- staff notes -->
			<div
				v-if="userStore.getContactType == 'Staff'"
				class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('205')">
					<div
						class="w-full px-4">
						<!-- notes -->
						<data-display
							:loading="loading"
							:value="job?.fieldData?.['job__JOBS_INFO__jobID::notes_jobs_staff_display'] || '—'" />
					</div>
				</collapsable-card>
			</div>
			
			<!-- introductions -->
			<div class="col-span-2 lg:col-span-1">
				<collapsable-card 
					:title="_localesStore.getLabel('347')">
					<JobIntroductions 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- staff participants -->
			<div
				v-if="userStore.getContactType == 'Staff'"
				class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('156')">
					<JobParticipants 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- items -->
			<div
				v-if="userStore.getContactType != 'Staff'"
				:class="['col-span-2']">
				<collapsable-card 
					:title="_localesStore.getLabel('187')">
					<JobItems 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- staff options -->
			<div
				v-if="userStore.getContactType == 'Staff'"
				class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('175')">
					<JobOptions 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- roles -->
			<div class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('179')">
					<JobRoles 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- participants -->
			<div
				v-if="userStore.getContactType != 'Staff'"
				class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('156')">
					<JobParticipants 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>
	
			<!-- options -->
			<div
				v-if="userStore.getContactType != 'Staff'"
				class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('175')">
					<JobOptions 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>
			
			<!-- variations -->
			<div class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('394')">
					<JobVariations 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- scenes -->
			<div 
				class="col-span-2"
				id="scenes">
				<collapsable-card 
					:title="_localesStore.getLabel('178')"
					no-fill>
					<div class="col-span-2 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
						<div
							v-for="scene in job?.portalData?.portal_scenes"
							:key="scene.recordId"
							class="col-span-1">
							<SceneCard
								:job-name="job?.fieldData?.name_bride_groom_display"
								:scene="scene" />
						</div>
					</div>
				</collapsable-card>
			</div>			

			<!-- borrowed items -->
			<div class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('219')">
					<JobBorrowedItems 
						:job="job"
						:loading="loading" />
				</collapsable-card>
			</div>

			<!-- documents -->
			<div class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('203')"
					no-fill>
					<div					
						class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 lg:gap-6">
						<a
							v-for="(document, i) in job?.portalData?.portal_documents"
							:key="i"
							class="w-full truncate hover:opacity-70"
							:href="document.signedUrl"
							download>
							<div
								v-if="document.ext == 'jpeg' || document.ext == 'jpg' || document.ext == 'png' || document.ext == 'gif'"
								class="flex bg-gray-100 border-2 border-gray-200  w-full h-48 m-auto text-brand-500 rounded-md">
								<img
									:src="document.signedUrl"
									class="w-full h-full object-cover rounded-md">
							</div>
							<div
								v-else
								class="flex bg-gray-100 border-2 border-gray-200  w-full h-48 m-auto text-brand-500 rounded-md">
								<img
									class="linked-icon w-16 m-auto"
									src="@/assets/icons/solid/document-fill.svg"
									alt="document icon">
							</div>
							<div class="text-gray-900 font-medium mt-2">
								{{ document['job__DOCUMENTS__jobID::name_display'] }}
							</div>
							<div class="text-sm">
								{{ document['job__DOCUMENTS__jobID::description_display'] }}
							</div>
						</a>
					</div>
				</collapsable-card>
			</div>
	
			
			
			

			<div
				v-if="job?.portalData?.portal_meetings.length"
				class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('412')"
					no-fill>
					<Meeting
						v-for="(meeting, i) in job?.portalData?.portal_meetings"
						class="first:rounded-t last:rounded-b rounded-t-none rounded-b-none"
						:key="i"
						:meeting="meeting" />
				</collapsable-card>
			</div>
			<div
				v-if="job?.portalData?.portal_related_jobs.length"
				class="col-span-2">
				<collapsable-card 
					:title="_localesStore.getLabel('612')"
					no-fill>
					<div
						v-for="(relatedJob, i) in job?.portalData?.portal_related_jobs"
						:key="i">
						<Job
							:job="relatedJob"
							related
							hide-line />
					</div>
				</collapsable-card>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobDetails' } // name the component
</script>

<script setup lang="ts">
	import { // api
		Auth
	} from '@/api/Auth'

	import { 
		getJobByRecordId,
		getJobPrintInstructions
	} from '../queries'

	import JobDetailsLocation from './DetailsLocation.vue' 	// components
	import JobCovidRestrictions from './CovidRestrictions.vue'
	import JobAdditionalDetails from './AdditionalDetails.vue'
	import JobDiscounts from './Discounts.vue'
	import JobIntroductions from './Introductions.vue'
	import JobItems from './Items.vue'
	import JobRoles from './Roles.vue'
	import JobOptions from './Options.vue'
	import JobVariations from './Variations.vue'
	import JobParticipants from './Participants.vue'
	import JobBorrowedItems from './BorrowedItems.vue'
	import JobTransportationDetails from './TransportationDetails.vue'
	import JobMeetingPlaces from './MeetingPlaces.vue'
	import JobLocations from './Locations.vue'
	import SceneCard from './SceneCard.vue'
	import Meeting from './Meeting.vue'
	import Job from './Job.vue'

	import EventBus from '@/events' // events

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // router
		useRouter
	} from 'vue-router'

	import { // stores
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // types
		FindParameters
	} from '@/types'

	import { 
		FileMakerRecord
	} from '@/types'

	import { // vue
		computed,
		inject,
		onBeforeUnmount,
		onMounted,
		ref
	} from 'vue'

	const router = useRouter()

	const _localesStore = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()

	const props = defineProps({
		recordId: {
			type: String,
			default: ''
		} 
	})

	const breadcrumb = computed(() => {
		return [
			{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}},
			{label: job.value?.fieldData?.name_bride_groom_display, to: {}},
		]
	})

	const loading = ref(false)

	const job = ref<FileMakerRecord>({} as FileMakerRecord)

	const query = computed(() => {
		const contactId = userStore.user.contactId
		const isStaff = userStore.getContactType === 'Staff'
		const contactIdField = isStaff ? 'job__JOBS_ROLES__jobID::_kf_contacts_id' : '_kf_contacts_id'

		const querySearch = [
			{
				[contactIdField]: contactId,
				recordId: props.recordId
			}
		]

		return querySearch
	})

	onMounted(async () => {
		getData()

		EventBus.on('change-language', async () => {
			await getData(true)
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('change-language', null)
	})

	
	const s3: any = inject('s3')
	async function getData(reset: boolean = false) {
		try {
			loading.value = true

			const contactId = userStore.user.contactId
			const sessionId = userStore.user.sessionId
			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				sessionId,
				contactId
			})

			let params: FindParameters = {
				query: query.value,
				limit: 1,
				"script.prerequest": "Set Language",
				"script.prerequest.param": scriptParams
			}

			// const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			// fetch job by record id
			let res = await getJobByRecordId(params)
			const record = res.response.data[0]
			job.value = record

			// get documents from S3
			job.value.portalData.portal_documents.forEach((doc: any) => {
				const s3Json = JSON.parse(doc?.['job__DOCUMENTS__jobID::s3JSONDocument'] || "{}")
				const key = s3Json?.s3FilePathShortRaw
				const ext = s3Json?.fileExt
				doc.ext = ext
				if(key) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: key,
						ResponseContentDisposition: `attachment;`
					}

					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							doc.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})


		}
		catch(e: any) {
			console.error(e)

			// redirect if server session expired 
			if(e?.code == "952") {
				await Auth.signOut()
				router.push({name: "Login"})
			}
		}
		finally {
			loading.value = false
		}
	}
	
	const printLoading = ref(false)
	
	const signedInStaffRole = computed(() => {
		if(userStore.getContactType === 'Staff') {
			const musicianRole = job.value.portalData.portal_musician_role_staff.filter((role: any) => {
				return role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::_kf_contacts_id'] === userStore.user.contactId
			})
			const liveRole = job.value.portalData.portal_live_role_staff.filter((role: any) => {
				return role?.['job__JOBS_ROLES__liveJobID_gStaffID::_kf_contacts_id'] === userStore.user.contactId
			})
			const standardRole = job.value.portalData.portal_standard_roles_staff.filter((role: any) => {
				return role?.['job__JOBS_ROLES__standardJobID_gStaffID::_kf_contacts_id'] === userStore.user.contactId
			})
			const roleName = [...musicianRole, ...liveRole, ...standardRole][0]['job__job_role__ROLES__roleID_instrumental_gStaffID::name_popup_instructions_join'].split("\r")[0]
			return roleName
		}
		else {
			return null
		}
	})

	async function printInstructions() {
		if(loading.value) return
		try {
			printLoading.value = true
			const contactId = userStore.user.contactId
			const sessionId = userStore.user.sessionId
			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				sessionId,
				contactId,
				jobId: job.value.fieldData._kp_jobs_id,
				role: signedInStaffRole.value
			})

			let res = await getJobPrintInstructions(scriptParams)
			const pdfBase64 = JSON.parse(res.response.scriptResult).results.file

			const linkSource = `data:application/pdf;base64,${pdfBase64}`
			const downloadLink = document.createElement("a")
			const fileName = JSON.parse(res.response.scriptResult).results.fileName

			downloadLink.href = linkSource
			downloadLink.download = fileName
			downloadLink.click()

		}
		catch(e) {
			console.error(e)
		}
		finally {
			printLoading.value = false
		}
	}

</script>
<style scoped>
.modal {
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(255,255,255); /* Fallback color */
  background-color: rgba(255,255,255,.8); /* Black w/ opacity */
}
</style>