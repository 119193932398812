<template>
	<div class="flex justify-between items-center pb-3 mb-10 border-b-2 border-brand-400">
		<tw-breadcrumb
			:crumbs="breadcrumb" />
	</div>

	<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 place-items-start">
		<job-role-time-card
			v-for="role in roles"
			:role="role"
			:key="role.roleID" />
	</div>
	<div
		v-if="noJobs"
		class="flex items-center justify-center w-full mt-10 text-4xl text-gray-300">
		<h1>{{ t('placeholders.noJobsToday') }}...</h1>
	</div>
</template>

<script lang="ts">
	export default { name: 'TodaysJobs' }
</script>

<script setup lang="ts">
	import JobRoleTimeCard from './components/JobRoleTimeCard.vue'

	import {
		getJobRolesByContactID,
	} from './queries'

	import {
		FindParameters
	} from '@/types'

	import EventBus from '@/events'

	// store
	import { 
		store as _userStore
	} from '@/store/user'
	import { 
		localesStore
	} from '@/store/locales'

	import {
		ref,
		computed,
		onMounted,
		onBeforeUnmount
	} from 'vue'

	import {
		useI18n
	} from 'vue-i18n'


	const userStore = _userStore()
	const _localesStore = localesStore()

	const {t} = useI18n()

	const loading = ref(false)

	const roles = ref<Object[]>([])
	const noJobs = ref(false)

	const breadcrumb = computed(() => {
		return [
			{label: t('nav.todaysJobs'), to: {name: 'todaysJobs'}}
		]
	})

	onMounted(async () => {
		getData()

		EventBus.on('change-language', async () => {
			await getData()
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('change-language', null)
	})

	async function getData() {
		try {
			loading.value = true
			const today = new Date()
			const contactId = userStore.user.contactId
			const sessionId = userStore.user.sessionId			
			const setLanguageScriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				sessionId,
				contactId
			})
			let params: FindParameters = {
				query: [
					{
						"JOBS_ROLES::_kf_contacts_id": contactId,
						"JOBS_ROLES::job_date_start": `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`
					}
				],
				"script.prerequest": "Set Language",
				"script.prerequest.param": setLanguageScriptParams
			}

			let res = await getJobRolesByContactID(params)

			const foundRoles = res?.response?.data?.map((record: any) => {
				const fieldData = record.fieldData
				return {
					nameBridgeGroomDisplay: fieldData['jobs_roles__JOBS__jobID::name_bride_groom_display'],
					dateStart: fieldData['job_date_start'],
					dateDayCode: fieldData['jobs_roles__JOBS__jobID::date_start_name_code'],
					timeStart: fieldData['jobs_roles__JOBS__jobID::time_start'],
					timeEnd: fieldData['jobs_roles__JOBS__jobID::time_end'],
					typeDisplay: fieldData['jobs_roles__JOBS__jobID::type_display'],
					categoryDisplay: fieldData['jobs_roles__JOBS__jobID::category_display'],
					companyDisplay: fieldData['jobs_roles__jobs__CONTACTS__locationID::company_display'],
					contactID: fieldData['_kf_contacts_id'],
					roleID: fieldData['_kp_jobs_roles_id'],
					recordID: fieldData['jobs_roles__JOBS__jobID::recordId'],
					departureTime: fieldData['departureTimeActual'],
					arrivalTime: fieldData['arrivalTimeActual']
				}
			})
			roles.value = [...foundRoles]
			if (roles.value.length === 0) {
				noJobs.value = true
			} else {
				noJobs.value = false
			}
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	}

</script>