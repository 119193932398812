<template>
	<div class="flex justify-between items-center pb-3 mb-8 border-b-2 border-brand-400">
		<tw-breadcrumb 
			:crumbs="breadcrumb" />
	</div>
	<div 
		v-show="loading"
		class="flex justify-start items-center space-x-4">
		<div class="w-16 h-16 rounded-md bg-gray-300 animate-pulse" />
		<div class="h-6 my-11 rounded-full bg-gray-300 animate-pulse w-1/2" />
	</div>
	<div
		v-show="!loading"
		class="flex justify-start items-center space-x-4">
		<img
			v-if="signedUrl"
			class="w-16 h-16 flex rounded-md object-cover mb-4 lg:mb-8"
			:src="signedUrl">
		<div
			v-else-if="!signedUrl"
			class="w-16 h-16 flex justify-center items-center rounded-md bg-gray-300 mb-4 lg:mb-8">
			<img
				class="grayed-icon h-10 w-10"
				src="@/assets/icons/solid/user-info-fill.svg"
				alt="video icon">
		</div>
		<h1
			v-show="!loading"
			class="page-title">
			{{ contact?.fieldData?.name_full_company_display }}
		</h1>
	</div>
	<div class="grid grid-cols-3 gap-4 lg:gap-6">
		<div :class="[userStore.getContactType === 'Staff' ? 'lg:col-span-2' : 'lg:col-span-3', 'col-span-3 lg:col-span-2']">
			<!-- account details -->
			<collapsable-card 
				:title="t('account.accountDetails')"
				fixed>
				<AccountDetails 
					:contact="contact"
					:loading="loading" />
			</collapsable-card>
		</div>
		<div 
			v-if="userStore.getContactType == 'Staff'"
			class="col-span-3 lg:col-span-1">
			<!-- personal information -->
			<collapsable-card 
				:title="localesStore.getLabel('384')"
				fixed>
				<PersonalInformation
					:contact="contact"
					:loading="loading" />
			</collapsable-card>
		</div>
		<div :class="[userStore.getContactType === 'Staff' ? 'lg:col-span-2' : 'lg:col-span-3', 'col-span-3 lg:col-span-2']">
			<!-- contact information -->
			<collapsable-card 
				:title="t('account.contactInformation')"
				fixed>
				<ContactInformation 
					:contact="contact"
					:loading="loading" 
					:contact-type="userStore.getContactType" />
			</collapsable-card>
		</div>
		<div 
			v-if="userStore.getContactType === 'Staff'"
			class="col-span-3 lg:col-span-1">
			<!-- areas -->
			<collapsable-card 
				:title="localesStore.getLabel('452')"
				fixed>
				<AreaDetails
					:contact="contact"
					:loading="loading" />
			</collapsable-card>
		</div>
		<div class="col-span-3 lg:col-span-3">
			<!-- bank information -->
			<collapsable-card 
				:title="localesStore.getLabel('76')">
				<BankInformation 
					:contact="contact"
					:loading="loading"
					:contact-type="userStore.getContactType" />
			</collapsable-card>
		</div>
		<div
			v-if="userStore.getContactType === 'Staff'"
			class="col-span-3 lg:col-span-3">
			<!-- Upcoming Unavailability -->
			<collapsable-card
				class="relative"
				:title="t('account.upcomingUnavailability')">
				<UnavailabilityDetails 
					class="relative"
					:contact="contact"
					:loading="loading"
					@refresh-account-data="async () => { await getData(false) }" />
			</collapsable-card>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'AccountMain' } // name the component
</script>

<script setup lang="ts">
	import {  // api
		getContactByRecordId
	} from './queries'

	import { 
		Auth
	} from '@/api/Auth'

		// components
	import AccountDetails from './components/AccountDetails.vue'
	import BankInformation from './components/BankInformation.vue'
	import ContactInformation from './components/ContactInformation.vue'
	import PersonalInformation from './components/PersonalInformation.vue'
	import AreaDetails from './components/AreaDetails.vue'
	import UnavailabilityDetails from './components/UnavailabilityDetails.vue'

		// events
	import EventBus from '@/events'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // router
		useRouter
	} from 'vue-router'

	import { // store
		store as _userStore
	} from '@/store/user'

	import { 
		localesStore as _localesStore
	} from '@/store/locales'

	import { // types
		FileMakerRecord
	} from '@/types'

	import { // vue
		inject,
		onBeforeUnmount,
		onMounted,
		ref
	} from 'vue'

	const loading = ref(false)

	const userStore = _userStore()
	const localesStore = _localesStore()

	const router = useRouter()

	const {t} = useI18n()

	const breadcrumb = ref([
		{label: 'Account', to: {name: 'Account'}}
	])

	const contact = ref<FileMakerRecord>({} as FileMakerRecord)

	const s3: any = inject('s3')
	const signedUrl = ref('')

	onMounted(async () => {
		loading.value = true
		await getData(false)

		EventBus.on('change-language', async () => {
			await getData(true)
		})

		// load image from s3
		try {
			const s3Json = JSON.parse(contact.value?.fieldData?.['contact__CONTACTS_INFO__contactID::s3JSONPicture'] || "{}")
			const key = s3Json?.thumbnail?.s3FilePathShortRaw

			let params = {
				Bucket: process.env.VUE_APP_S3_BUCKET,
				Key: key
			}

			s3.getSignedUrlPromise('getObject', params).then(
				function (url: string) {
					signedUrl.value = url
				}, 
				function (err: Error) { 
					throw err
				}
			)
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})

	onBeforeUnmount(() => {
		EventBus.off('change-language', null)
	})	

	async function getData(showLoading: boolean) {
		try {
			loading.value = true
			const scriptParams = JSON.stringify({
				language: localesStore.getFmLanguage
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			const recordId = userStore.user.recordId

			// fetch job by record id
			let res = await getContactByRecordId(recordId, params)
			const record = res.response.data[0]
			contact.value = record
		}
		catch(e: any) {
			console.log(e)

			// redirect if server session expired 
			if(e?.code == "952") {
				await Auth.signOut()
				router.push({name: "Login"})
			}
		}
		finally {
			loading.value = false && !showLoading
		}
	}
</script>